import React from 'react'

export default function Footer() {
  return (
    <footer className="footer" style={{ position: 'relative', bottom: 33, width: '100%', padding: '5px', backgroundColor: 'transparent', backgroundImage: 'none' }}>
  <div className="content has-text-centered">
    <p style={{color: '#ff8a00'}}>
      © 2024 Mensch und Maschine - &nbsp;
      <span className="footer-link">
        <a href="https://www.mum.de/unternehmen/datenschutz">
          Impressum
        </a>
      </span>
      &nbsp;-&nbsp;
      <span className="footer-link">
        <a href="https://www.mum.de/unternehmen/datenschutz">
          Datenschutz
        </a>
      </span>
      &nbsp;-&nbsp;
      <span className="footer-link">
        <a href="https://www.mum.de/loesungen/mum-cloud-services/mumiverse##">
          Cookie Consent Settings
        </a>
      </span>
      &nbsp;-&nbsp;
      <span className="footer-link">
        <a href="https://www.mum.de/unternehmen/agb">
          AGB
        </a>
      </span>
    </p>
  </div>
</footer>
  )
}
