import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../config.js";
import Authentication from "../Authentication";

const RequireAuth = ({ children, setUserLicenses }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkUserAuth = async () => {
      const auth = new Authentication();
      try {
        const idTokenPayload = await auth.login();
        if (idTokenPayload) {
          const email = idTokenPayload.email;
          const session = sessionStorage.getItem("idToken");
          const response = await axios.get(
            `${config.mumapapi.invokeUrl}/UserLicenses?email=${encodeURIComponent(email)}`,
            {
              headers: {
                Authorization: `Bearer ${session}`,
              },
            }
          );
          const { licenses } = response.data;
          setUserLicenses(licenses);

          const hasGeneralAccess = licenses.includes("Download Portal General Access");
          const hasQTOBoosterLicense = licenses.includes("QTO Booster 8") ||
            licenses.includes("QTO Booster 7") ||
            licenses.includes("QTO Booster 6");
          const hasBimBoosterLicense = licenses.includes("Bim Booster");  

          const hasValidLicense = hasGeneralAccess || hasQTOBoosterLicense || hasBimBoosterLicense;

          setIsLoading(false);

          if (!hasValidLicense) {
            return;
          }
        } else {
          setIsLoading(false);
          setUserLicenses(["Download Portal General Access"]);
        }
      } catch (error) {
        setIsLoading(false);
        setUserLicenses(["Download Portal General Access"]);
      }
    };

    checkUserAuth();
  }, [setUserLicenses]);

  return (
    <>
      {!isLoading && children}
    </>
  );
};

export default RequireAuth;
